.blog-sidebar{
    .title{
        font-size: 18px;
        font-weight: initial;
        line-height: 40px;
        background-color: #38f;
        color: #fff;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        padding-left: 15px;
        i{
            margin-right: 5px;
            position: relative;
        }
    }
    .content{
        padding: 15px;
    }

    .sidebar-block{
        background-color: #fff; 
        box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.1), 0 2px 4px 0px rgba(0, 0, 0, 0.1);
        transition: all 0.25s ease 0s, opacity 0.6s cubic-bezier(0.5, 0, 0, 1) 0s;
        border-radius: 12px;
        margin: 20px auto;
    }
}

.sidebar-music{
    padding-bottom: 15px;
    .content {
        margin: 15px;
    }
}

.sidebar-info{
    text-align: center;
    padding: 15px;
    img{
        width: 200px;
    }
    .func-list {
        font-size: 16px;
        margin-top: 10px;
        display: block;
        height: 32px;
        li{
            float: left;
            margin-left: 20px;
        }
        a{
            line-height: 32px;
            display: inline-block;
            width: 32px;
            height: 32px;
            margin: 4px;
            border-radius: 100px;
            &:hover{
                background: #3388ff4a;
                color: #38f;
            }
        }
        i{
            font-size: 20px;
        }
        img{
            width: 19px;
            height: 19px;
            position: relative;
            top: -2px;
        }
    }
    .nick {
        font-size: 22px;
        line-height: 50px;
    }
}

.sidebar-nav{
  li{
      float: left;
      width: 33.3%;
      margin-top: 10px;
      font-size: 12px;
  }
  a{
      display: inline-block;
      width: 70px;
      height: 70px;
      padding: 10px 0;
      border-radius: 50%;
      transition: all .5s ease;
      &:hover{
        background: #3388ff4a;
        color: #38f;
      }
  }
  .content{
    text-align: center;
    i{
        display: block;
        font-size: 22px;
        margin-bottom: 5px;
    }
  }
}
.sidebar-wel{
    line-height: 30px;
}