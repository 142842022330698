.footer{
    text-align: center;
    margin: 50px 0;
    .blog-link{
        display: inline-block;
        height: 25px;
        line-height: 25px;
        li{
            float: left;
            margin-left: 15px;
        }
        a:hover{
          color: #38f;
        }
    }
    .bottom{
        margin-top: 20px;
    }
    .github-badge {
        display: inline-block;
        border-radius: 4px;
        text-shadow: none;
        font-size: 12px;
        color: #fff;
        line-height: 15px;
        background-color: #ABBAC3;
        margin-bottom: 5px;
        margin-left: 15px;
        a{
            color: #fff;
        }
    }
    .badge-subject {
        display: inline-block;
        background-color: #4D4D4D;
        padding: 4px 4px 4px 6px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .badge-value {
        display: inline-block;
        padding: 4px 6px 4px 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    .bg-orange {
        background-color: #FFA500;
    }
    .bg-brightgreen {
        background-color: #4DC820;
    }
    .bg-blue {
        background-color: #007EC6;
    }
    .bg-red {
        background-color: #e05d44;
    }
    .bg-react{
        background-color: #61dafb;
    }
    .bg-brightgreen {
        background-color: #4DC820;
    }
    .bg-blueviolet {
        background-color: #8833D7;
    }
    .bg-vue{
        background-color: #42b983;;
    }
    .bg-ant{
        background-color: #1890ff;
    }
}