.blog-header{
    position: fixed;
    top: 0;
    z-index: 9;
    width: 100%;
    background-color: #6190e8;
    height: 50px;
    a{
        color: #fff;
    }
    .logo{
        color: #fff;
        font-size: 22px;
        font-weight: bold;
        line-height: 50px;
    }
    ul{
        font-size: 14px;
        li{
            float: left;
            line-height: 50px;
            margin-left: 15px;
        }
        i{
            margin-right: 5px;
            font-size: 16px;
        }
        a{
            cursor: pointer;
            display: block;
            padding: 0 15px;
            height: 50px;
            text-decoration: none;
            transition: all 0.3s ease;
            &:hover,&.active{
                border-bottom: 5px solid #FFFFFF;
                background-color: #2875e8;
            }
        }
    }
}
@media screen and(max-width: 600px) {
    .blog-header{
        display: none!important;
    }
}