body, h1, h2, h3, h4, h5, h6, hr, p, blockquote, dl, dt, dd, ul, ol, li, pre, form, fieldset, legend, button, input, textarea, th, td {
  margin: 0;
  padding: 0;
  font-family: "Georgia," Times New Roman ",Times," Kai "," Kaiti SC "," KaiTi "," BiauKai "," 楷体 "," 楷体_GB2312 ",serif";
}

@font-face {
  font-family: 'webfont';
  font-display: swap;
  src: url('//at.alicdn.com/t/webfont_tro5f7bfi8m.eot'); /* IE9*/
  src: url('//at.alicdn.com/t/webfont_tro5f7bfi8m.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('//at.alicdn.com/t/webfont_tro5f7bfi8m.woff2') format('woff2'),
  url('//at.alicdn.com/t/webfont_tro5f7bfi8m.woff') format('woff'), /* chrome、firefox */
  url('//at.alicdn.com/t/webfont_tro5f7bfi8m.ttf') format('truetype'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url('//at.alicdn.com/t/webfont_tro5f7bfi8m.svg#AlibabaPuHuiTiL') format('svg'); /* iOS 4.1- */
}

html{
  font-family:"webfont" !important;
  font-size:12px;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
body{
  background: #f1f1f1;
}

ul, ol li {
  list-style: none;
}

a{
  text-decoration: none;
  color: #444444;
  cursor: pointer;
  transition: all 0.25s ease;
  &:hover{
    text-decoration: none;
    color:  #444444;
  }
}

%content-shadow{
  transition: all 0.25s ease 0s, opacity 0.6s cubic-bezier(0.5, 0, 0, 1) 0s;
  background: #FFFFFF;
  box-shadow: 0 1px 2px 0px rgba(0,0,0,0.1), 0 2px 4px 0px rgba(0,0,0,0.1);
  border-radius: 12px;
  margin: 20px auto;
  padding: 40px 30px;
  &:hover{
      box-shadow: 0 2px 4px 0px rgba(0,0,0,0.1), 0 4px 8px 0px rgba(0,0,0,0.1), 0 8px 16px 0px rgba(0,0,0,0.1), 0 16px 32px 0px rgba(0,0,0,0.1);
  }
}
.to-top{
  position: fixed;
  right: 50px;
  bottom: 100px;
  cursor: pointer;
  transition: all 0.6s ease;
  display: inline-block;
  width: 48px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  i{
    font-size: 20px;
    margin-top: 10px;
  }
  &:hover{
    transform: scale(1.2);
    border-radius: 25%;
    background: #1BC3FB;
    color: white;
    box-shadow: 0 2px 4px 0px rgba(0,0,0,0.1), 0 4px 8px 0px rgba(0,0,0,0.1), 0 8px 16px 0px rgba(0,0,0,0.1), 0 16px 32px 0px rgba(0,0,0,0.1);
  }
}

.alert-danger{
  width: 50%;
  position: fixed;
  top: 250px;
  margin-left: 25%;
}