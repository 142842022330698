.blog-msg{
    @extend %content-shadow;
    .avator{
        text-align: center;
    }
    .talk{
        margin: 20px 0;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        line-height: 80px;
    }
    .title{
        font-size: 32px;
        text-align: center;
        line-height: 100px;
        font-weight: bold;
    }
    .avator img{
        width: 80px;
    }
}