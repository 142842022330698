.blog-cat{
    .home-menu a{
        color: #fff!important;
        border: 1px solid transparent!important;
    }
    .bg{
        height: calc(52vh)!important;
    }
    &.cover{
        height: calc(54vh)!important;
    }
    .bg-img{
        height: 580px!important;
    }
    .home-search{
        margin-top: 220px!important;
    }
    .home-search{
        .icon{
            color: #fff!important;
        }
        input::-webkit-input-placeholder { /* WebKit browsers 适配谷歌 */
            color: #fff;
        }
        input:-moz-placeholder { /* Mozilla Firefox 4 to 18 适配火狐 */
            color: #fff;
        }
        input::-moz-placeholder { /* Mozilla Firefox 19+ 适配火狐 */
            color: #fff;
        }
        input:-ms-input-placeholder { /* Internet Explorer 10+  适配ie*/
            color: #fff;
        }
    }
}
.home-top{
    position: relative;
    .bg{
        top: 0px;
        overflow: hidden;
        margin: 0px;
        padding: 0px;
        height: calc(100vh);
        width: 100%;
        z-index: -999998;
        position: absolute;
        background: #FFFFFF;
    }
    &.cover{
        top: 0;
        left: 0;
        max-width: 100%;
        height: calc(100vh);
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        align-items: center;
        align-self: center;
        align-content: center;
        padding: 20px;
    }
    .bg-img{
        position: absolute;
        margin: 0px;
        padding: 0px;
        border: none;
        width: 100%;
        max-width: 1000px;
        height: 810px;
        max-height: none;
        max-width: none;
        z-index: -999999;
        left: 0px;
        top: -90px;
    }
    @media screen and(max-width: 600px) {
        .bg-img{
            display: none;
        }
    }
    .home-text{
        margin-top: 100px;
        color: #38f;
        font-size: 16px;
    }
    .home-search{
        margin-top: calc(40vh + 2*20px);
        position: relative;
        max-width: calc(100% - 1*20px);
        width: 360px;
        line-height: 36px;
        vertical-align: middle;
        .searchFrom{
            position: relative;
            display: block;
            width: 100%;
        }
        .input{
            display: block;
            font-size: 16px;
            line-height: 16px;
            height: 44px;
            width: 100%;
            color: #38f;
            box-shadow: none;
            box-sizing: border-box;
            -webkit-appearance: none;
            padding-left: 36px;
            border-radius: 56px;
            background: rgba(33,150,243,0.1);
            border: 1px solid #38f;;
            outline: none;
            transition: all .3s ease;
            &:focus{
                border: 3px solid #38f;
            }
        }
        .icon{
            position: absolute;
            display: block;
            line-height: 44px;
            height: 44px;
            width: 32px;
            top: 0;
            left: 15px;
            font-size: 16px;
            color: rgba(51,51,51,0.6);
        }
    }
    .home-menu{
        margin-top: 20px;
        li{
            float: left;
        }
        a{
            padding: 4px 8px;
            font-size: 14px;
            border-bottom: 1px solid #fff;
            outline: none;
            text-decoration: none;
            &.active,&:hover{
                color: #2875e8;
                border-bottom: 1px solid #2875e8!important;
            }
        }
    }
}

.home-main{
  .item-article{
    @extend %content-shadow;
    .title{
        font-family:"webfont" !important;
        font-size: 20px;
        font-weight: 400;
        line-height: 40px;
    }
    .article-info{
        color: rgba(51,51,51,0.7);
        line-height: 40px;
        border-bottom: 1px solid #ddd;
        span {
            margin-right: 10px;
        }
        i{
            margin-right: 4px;
            color: #999;
        }
    }
    .article-content{
        padding: 15px 0;
        line-height: 25px;
        text-indent: 25px;
    }
    .article-bom{
        button{
            background-color: #38f;
            border-color: #38f;
        }

    }
  }
}

.article-more{
    text-align: center;
    .more-btn{
        cursor: pointer;
        margin: 20px auto;
        background-color: #fff;
        display: inline-block;
        line-height: 20px;
        padding: 20px 40px;
        border-radius: 30px;
        box-shadow: 0 1px 2px 0px rgba(0,0,0,0.1), 0 2px 4px 0px rgba(0,0,0,0.1);
    }
    i{
        margin-left: 10px;
    }
}
